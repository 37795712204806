<template>
    <div v-if="isAllowedTo('SupplierCentral/getSupplierById')" class="full-height-layout fill">
        <router-view ref="routeview" class="full-height-layout"></router-view>
    </div>
    <div v-else>
        <p class="permission-message">Not allowed to see this view</p>
    </div>
</template>

<script>
import CONFIG from '@root/config'

export default {
    name: 'SupplierView',
    data () {
        return {
            supplierName: '',
            breadcrumbElement: {
                name: '...',
            },
            supplierDetails: null,
        }
    },

    created () {
        this.supplierDetails = new Promise((resolve, reject) => {
            const url = CONFIG.API.ROUTES.SUPPLIERS.GET.replace('{supplierId}', this.supplierId)
            this.addJob(url)

            this.$api.get(url).then(({ data }) => {
                this.breadcrumbElement.name = data.data.supplierName
                this.supplierName = data.data.supplierName
                this.$bus.$emit('supplierWasFetched', data.data)
                resolve(data.data)
            }).catch(data => {
                reject(data)
            }).finally(() => {
                this.finishJob(url)
            })
        })

        this.$store.dispatch('suppliers/fetchSupplierLocations', this.supplierId)
    },

    destroyed () {
        this.$bus.$emit('supplierWasFetched', null)
    },
}
</script>
